export type Locale = 'en' | 'ru' | 'be' | 'zh' | 'az' | 'uz' | 'tg' | 'kk' | 'ky';

export type SoundLocale = 'ru' | 'en' | 'kk' | 'uz';

export const LocaleEnum = {
  en: 'en' as Locale,
  ru: 'ru' as Locale,
  be: 'be' as Locale,
  zh: 'zh' as Locale,
  az: 'az' as Locale,
  uz: 'uz' as Locale,
  tg: 'tg' as Locale,
  kk: 'kk' as Locale,
  ky: 'ky' as Locale,
} as const;
