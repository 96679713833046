import { z } from 'zod';

import { createEnv } from '@t3-oss/env-core';

let envVars: {
  [key: string]: string | undefined;
} = {};

if (typeof window === 'undefined') {
  const dotenvFlow = await import('dotenv-flow');

  dotenvFlow.config();

  envVars = process.env;
} else {
  envVars = import.meta.env;
}

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z
      .enum(['development', 'test', 'production'])
      .default('development'),
  },

  clientPrefix: 'VITE_PUBLIC_',

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `VITE_PUBLIC_`.
   */
  client: {
    VITE_PUBLIC_API_URL: z.string().url(),
    VITE_PUBLIC_WEB_URL: z.string().url(),
  },

  /**
   * You can't destruct `import.meta.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: envVars.NODE_ENV,
    VITE_PUBLIC_WEB_URL: envVars.VITE_PUBLIC_WEB_URL,
    VITE_PUBLIC_API_URL: envVars.VITE_PUBLIC_API_URL,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!envVars.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
